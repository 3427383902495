<div
  class="product-card"
  [ngClass]="{ minimal: minimal }"
  (click)="goToProductDetails(product)"
>
  <div class="offer__banner" *ngIf="product.localProduct.is_discount">
    <p>{{ "products.offer" | apiTranslation }}</p>
  </div>
  <div class="product-card--wrapper">
    <div
      class="overlay"
      [ngStyle]="{
    'background-color':  product.branch.color,
  }"
    ></div>
    <app-card
      [headerTitle]="product.branch.name"
      [headerIcon]="product.branch.icon"
      [headerImage]="product.localProduct.image"
      [color]="product.branch.color"
    >
      <ng-template #cardContent>
        <div class="card-content justify-content-between">
          <div class="flex flex-column justify-content-between h-full">
            <div class="product-title branch-name">
              📍 {{ product.branch.name }}
            </div>
            <div class="product-title">
              {{ product.localProduct.name }}
            </div>
            <div
              class="product-description"
              [innerHTML]="product.localProduct.description"
            ></div>
          </div>
          <div class="price">
            <div class="price-value">
              <h1>{{ product.Price }} SAR</h1>
            </div>
            <p style="text-align: end; color: rgb(167, 167, 167)">
              {{ "products.per_person" | apiTranslation }}
            </p>
          </div>
          <div class="card-actions">
            <div class="card-action">
              <button
                pButton
                pRipple
                [label]="
                  'branches.products.product.view_details' | apiTranslation
                "
                icon="pi pi-arrow-up-left"
                iconPos="right"
                (click)="goToProductDetails(product)"
              ></button>
            </div>
            <div class="card-action">
              <button
                pButton
                pRipple
                [label]="'products.add_to_cart' | apiTranslation"
                icon="pi pi-shopping-cart"
                iconPos="right"
                (click)="addToCart(product, $event)"
              ></button>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #cardFooter>
        <div class="d-flex jcb aic">
          <p style="font-weight: 700">
            <!-- {{ "branches.products.product.start_from" | apiTranslation }} -->
          </p>
        </div>
      </ng-template>
    </app-card>
  </div>
</div>
<p-toast position="top-center" key="confirm" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2">
        <strong
          ><span class="font-bold text-900">{{
            "general.toast.success" | apiTranslation
          }}</span></strong
        >
      </div>
      <div class="font-medium text-lg my-3 text-900">{{ message.summary }}</div>
      <p-button
        class="p-button-sm toast-btn"
        label="{{ 'products.toast.navigate_cart' | apiTranslation }}"
        (click)="onConfirm()"
      ></p-button>
    </div>
  </ng-template>
</p-toast>
