import { DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { FooterComponent } from '../../../core/widgets/footer/footer.component';
import { HeaderComponent } from '../../../core/widgets/header/header.component';
import { Product } from '../../../products/util/product.model';
import { CartService } from '../../data-access/cart.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckoutService } from '../../data-access/checkout.service';
import { Subject, Subscription, filter } from 'rxjs';
import { AllowNumbersAndLettersDirective } from '../../../directives/allow-numbers-and-letters.directive';
import { LanguageService } from '../../../core/services/language-service/language.service';
import { ApiTranslationPipe } from '../../../core/pipes/api-translation.pipe';
import { NgxIkImageCdnComponent } from '../../../shared/ngx-ik-image-cdn/ngx-ik-image-cdn.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileService } from '../../../user/data-access/profile.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [
    NgFor,
    DecimalPipe,
    RouterModule,
    FooterComponent,
    NgClass,
    HeaderComponent,
    ButtonModule,
    RippleModule,
    ToastModule,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    AllowNumbersAndLettersDirective,
    ApiTranslationPipe,
    NgxIkImageCdnComponent,
    TranslateModule,
  ],
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [MessageService],
})
export class CartComponent implements OnInit, OnDestroy {
  router = inject(Router);
  route = inject(ActivatedRoute);
  cartService = inject(CartService);
  profileService = inject(ProfileService);
  languageService = inject(LanguageService);
  messageService = inject(MessageService);
  _cd = inject(ChangeDetectorRef);
  checkoutService = inject(CheckoutService);

  checkCardLoading: boolean = false;
  cardNumberInput: { [key: string]: string | null } = {};
  cardNumberStatus: { [key: string]: boolean | null } = {};
  cardNumberName: { [key: string]: string | null } = {};
  private cardNumberChange$ = new Subject<{
    siteId: string;
    cardNumber: string;
  }>();
  private subscriptions: Subscription[] = [];
  private queryParamsSubscription: Subscription | null = null;

  cart: (Product & { quantity: number })[] = [];
  errorIndex!: number | null;
  ownCards: { [key: string]: string[] } = {};

  constructor() {
    // Subscribe to router events
    this.router.events
      .pipe(
        // Filter for NavigationEnd event to ensure navigation is complete
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
      });
  }

  ngOnInit(): void {
    this.cart = this.cartService.getItems();
    this.cart.forEach((prod) => {
      this.cardNumberInput[`${prod.branch.siteId}`] = null;
      this.cardNumberStatus[`${prod.branch.siteId}`] = null;
      this.cardNumberName[`${prod.branch.siteId}`] = null;
    });

    if (!this.cart.length) {
      this.router.navigate(['/']);
    }

    this.getAllCards();

    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        if (params['error']) {
          this.errorIndex = params['error'];
          setTimeout(() => {
            this.messageService.add({
              severity: 'error',
              summary: this.languageService.translate('general.toast.error'),
              detail: this.languageService.translate('booking.checkout.error'),
            });
          }, 100);
        }
      }
    );

    const sub = this.cardNumberChange$
      // .pipe(
      //   debounceTime(300) // Adjust time as needed
      // )
      .subscribe(({ siteId, cardNumber }) => {
        this.checkCardValidity(siteId, cardNumber);
      });

    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    // Clean up subscriptions
    this.subscriptions.forEach((sub) => sub.unsubscribe());

    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  getAllCards() {
    if (!localStorage.getItem(environment.jwtKey)) return;

    this.profileService.getAllCards().subscribe((res) => {
      res.forEach((card) => {
        card.semnox_details['SiteId'].forEach((siteId: any) => {
          if (this.ownCards.hasOwnProperty(siteId)) {
            this.ownCards[siteId].push(card.card_number);
          } else {
            this.ownCards[siteId] = [card.card_number];
          }
        });
      });
    });
  }

  removeItem(productId: number, index: number) {
    this.cartService.removeItem(productId);
    this.cart = this.cartService.getItems();
    if (this.cart.length == 0) {
      this.router.navigate(['/']);
    }
    if (index == this.errorIndex) {
      this.errorIndex = null;
    }
  }

  incrementItem(productId: number) {
    this.cartService.increaseQuantity(productId);
    this.cart = this.cartService.getItems();
  }

  decrementItem(productId: number) {
    this.cartService.decreaseQuantity(productId);
    this.cart = this.cartService.getItems();
    if (this.cart.length == 0) {
      this.router.navigate(['/']);
    }
  }

  totalTax() {
    return this.cartService.totalTax();
  }

  totalPrice() {
    return this.cartService.totalPrice();
  }

  goToCheckout() {
    let status = true;
    Object.keys(this.cardNumberInput).forEach((key) => {
      const value = this.cardNumberInput[key];
      if ((value && value.length < 8) || this.cardNumberStatus[key] == false) {
        this.cardNumberStatus[key] = false;
        status = false;
      }
    });
    if (!status) {
      this.messageService.add({
        severity: 'error',
        summary: this.languageService.translate('general.toast.error'),
        detail: this.languageService.translate(
          'booking.cart.card_number_error'
        ),
      });
      return;
    }

    let myObject: any = {};
    Object.keys(this.cardNumberName).forEach((key) => {
      if (this.cardNumberName[key] != null) {
        myObject[key] = this.cardNumberInput[key];
      }
    });
    const serializedObj = JSON.stringify(myObject);
    const encodedObj = encodeURIComponent(serializedObj);
    const queryParams = Object.keys(myObject).length
      ? { cards: encodedObj }
      : {};

    this.router.navigate(['/checkout'], {
      queryParams: queryParams,
    });
  }

  onCardNumberChange(siteId: string, cardNumber: string) {
    // Emit value changes to the subject
    if (cardNumber && cardNumber.length == 8 && siteId) {
      this.checkCardLoading = true;
      cardNumber = cardNumber.toUpperCase();
      this.cardNumberChange$.next({ siteId, cardNumber });
    } else {
      this.cardNumberStatus[siteId] = null;
      this.cardNumberName[siteId] = null;
      this._cd.markForCheck();
    }
  }

  checkCardValidity(branch: string, cardNumber: string) {
    this.checkoutService
      .checkCardValidity(branch, cardNumber)
      .subscribe((res) => {
        this.cardNumberStatus[branch] = res == null ? false : true;
        if (this.cardNumberStatus[branch]) {
          this.cardNumberName[branch] = res;
        }
        this._cd.markForCheck();
      })
      .add(() => {
        this.checkCardLoading = false;
      });
  }
}
